<template>
  <div class="component ak-navigation">
    <div class="ak-navigation__inner">
      <span v-for="(item, index) in menu" v-bind:key="index">
        <a
          :href="item.url"
          v-if="item.name == 'fashion' || modules.indexOf(item.name) > -1"
        >
          <div class="ak-navigation__item" :data-id="index">
            {{ item.name }}
          </div>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "AbstraktionNavigation",
  props: {
    modules: {
      default: [],
    },
  },
  data() {
    return {
      menu: [
        {
          name: "fashion",
          url: "/",
        },
        {
          name: "color",
          url: "/color/",
        },
        {
          name: "visual",
          url: "/visual/",
        },
        {
          name: "fabric",
          url: "/fabric/",
        },
        {
          name: "studio",
          url: "/studio/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.ak-navigation {
  &__inner {
    //background-color: #fff;
    padding: 0.1rem 0.5rem 0.1rem;
    animation: fadein 10s forwards;
    -webkit-animation: fadein 10s forwards;

    &:hover {
      opacity: 1 !important;
    }
  }
  transition: opacity 1s;
  background-color: #fff;

  font-size: 0.8rem;
  text-transform: uppercase;
  border-bottom: 1px solid #f7f7f7;

  a:last-child &__item {
    border-right: none;
  }

  &__item {
    opacity: 0;

    animation: fadein 10s forwards;
    -webkit-animation: fadein 10s forwards;
    color: #000;
    transition: all 1s;
    cursor: pointer;
    border-right: 1px solid #f7f7f7;
    display: inline-block;
    padding: 0 0.5rem;
    min-width: 4rem;
    text-align: center;

    &:hover {
      color: #fff;
      background-color: #000;
      opacity: 1 !important;
    }
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@-o-keyframes fadein {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
</style>